<section class="background" [ngStyle]="{ background: background, height: backgroundHeight }"></section>
<img *ngIf="isSb1" class="sb1-illustration" src="config/dist/theme/assets/woman-on-couch.svg" alt="Woman on a couch" />

<section class="content">
    <section class="illustration-wrapper">
        <img *ngIf="illustration" [src]="illustration" />
    </section>

    <article class="authentication-component">
        <h1>{{ headerText }}</h1>

        <small class="alert error" *ngIf="errorMessage">
            {{ errorMessage }}
        </small>

        <!-- This should be it's own component. -->
        <section class="signup-step" *ngIf="!errorMessage && !busyAgreement">
            @if (isDnb) {
                <section class="init-subtitle">
                    {{
                        companyType === 'demo'
                            ? 'Opprett demo med BankID og så kan du registerere et ekte selskap senere med noen få tastetrykk'
                            : 'Registrer deg med BankID og få full integrasjon mellom nettbanken og regnskapet'
                    }}
                </section>

                <bankid-svg r="76" g="28" b="123"></bankid-svg>

                <button (click)="goToBankIdSignUp(bankidIdp)" class="c2a">
                    {{ companyType === 'demo' ? 'Opprett demo med BankID' : 'Registrer med BankID' }}
                </button>

                <section class="bankid-agreement" *ngIf="companyType !== 'demo'" style="margin-bottom: 32px">
                    <section
                        [@shake]="shakeAlert"
                        (@shake.done)="shakeAlert = false"
                        *ngIf="showHasToAgreePrompt && !hasAgreedToCustomerAgreement"
                        class="alert warn"
                    >
                        <i class="material-icons ng-star-inserted"> info_outline </i>
                        Du må godta våre vilkår for å kunne registrere deg
                    </section>

                    <rig-checkbox [(ngModel)]="hasAgreedToCustomerAgreement">
                        Ved å fortsette godtar jeg DNB Regnskap sin
                        <a (click)="$event.preventDefault(); openAgreement()">lisensavtale</a> og bekrefter at jeg har
                        lest DNB sin <a (click)="$event.preventDefault(); openGdprAgreement()">personvernerklæring</a>.
                        Avtalen vil automatisk gå over til en betalbar tjeneste ved utløp av den kostnadsfrie perioden.

                        <uni-tooltip
                            [type]="'info'"
                            [text]="
                                'DNB Regnskap kan benyttes vederlagsfritt i den avtalte kostnadsfrie perioden. ' +
                                'Deretter påløper det abonnements- og transaksjonskostnader i henhold til egen prisliste. ' +
                                'Etter den kostnadsfrie perioden blir kunden automatisk trukket månedlig på den kontoen kunden ' +
                                'har oppgitt som belastningskonto i nettbanken, eller motta en faktura.'
                            "
                        >
                        </uni-tooltip>
                    </rig-checkbox>
                </section>

                <section class="bankid-agreement" *ngIf="companyType === 'demo'" style="margin-bottom: 32px">
                    Ved å registrere profil får du en begrenset lisens til å teste funksjonalitet i DNB Regnskap gratis
                    i prøveperioden. DNB vil behandle opplysninger om deg som du har oppgitt for å levere testtilgangen,
                    for markedsføringsformål til din bedrift og for sikkerhetsformål
                </section>

                <!-- Temp removed, also added extra margin (style=) to the two bankid-agreement sections above while this is removed

                     <div class="separator"></div>

                     <a class="email-signup" (click)="goToEmailSignup()">
                         <span class="highlight-link">Klikk her</span> om du ønsker å registrere deg med e-post
                     </a>

                 -->
            } @else if (isSb1) {
                <section class="init-subtitle">
                    {{
                        companyType === 'demo'
                            ? 'For at vi skal kunne opprette demo, må du først identifisere deg med BankID.'
                            : 'For at vi skal kunne koble nettbanken automatisk sammen med regnskapsprogrammet, må du først identifisere deg med BankID.'
                    }}
                </section>

                <section class="bankid-agreement">
                    <rig-checkbox *ngIf="companyType !== 'demo'" [(ngModel)]="hasAgreedToCustomerAgreement">
                        Ved å fortsette godtar jeg gjeldende
                        <a (click)="$event.preventDefault(); openAgreement()">avtalevilkår</a> og
                        SpareBank&nbsp;1&nbsp;sin
                        <a (click)="$event.preventDefault(); openGdprAgreement()">personvernerklæring</a>
                    </rig-checkbox>

                    <rig-checkbox *ngIf="companyType === 'demo'" [(ngModel)]="hasAgreedToCustomerAgreement">
                        Ved å fortsette godtar jeg SpareBank&nbsp;1&nbsp;sin
                        <a (click)="$event.preventDefault(); openGdprAgreement()">personvernerklæring</a>
                    </rig-checkbox>
                </section>

                <section class="button-row">
                    <button (click)="goToBankIdSignUp()" [disabled]="!hasAgreedToCustomerAgreement" class="c2a">
                        Identifiser deg med BankID
                    </button>

                    @if (flow !== 2) {
                        <a (click)="goToEmailSignup()">Registrer deg manuelt med e-post</a>
                    }
                </section>
            } @else if (isEika) {
                <section class="demo-subtitle" *ngIf="companyType === 'demo'">
                    <h1 style="font-size: 20px">Bekreft identiteten din med BankID</h1>
                </section>

                <section class="init-subtitle">
                    {{
                        companyType === 'demo'
                            ? 'For at vi skal kunne opprette demo, må du først godta vår personvernerklæring, og identifisere deg med BankID.'
                            : 'For at vi skal kunne koble nettbanken automatisk sammen med regnskapsprogrammet,' +
                              ' må du først godta lisensavtale og personvernerklæring, og identifisere deg med BankID.'
                    }}
                </section>

                <section class="mb-4">
                    <rig-checkbox *ngIf="companyType !== 'demo'" [(ngModel)]="hasAgreedToCustomerAgreement">
                        Jeg godtar gjeldende
                        <a class="eika-special-link" (click)="$event.preventDefault(); openAgreement()">lisensavtale</a>
                        og Eika Regnskap sin
                        <a class="eika-special-link" (click)="$event.preventDefault(); openGdprAgreement()"
                            >personvernerklæring.</a
                        >
                        Avtalen vil automatisk gå over til en betalbar tjeneste ved utløp av den kostnadsfrie perioden.
                    </rig-checkbox>

                    <rig-checkbox *ngIf="companyType === 'demo'" [(ngModel)]="hasAgreedToCustomerAgreement">
                        Jeg godtar Eika Regnskap sin
                        <a class="eika-special-link" (click)="$event.preventDefault(); openGdprAgreement()"
                            >personvernerklæring</a
                        >
                    </rig-checkbox>
                </section>

                <section *ngIf="companyType !== 'demo'" class="alert info">
                    <i class="material-icons-outlined">info</i>
                    <span>
                        Informasjon om Bedriften i regnskapssystemet vil deles med selskapene i Eika Gruppen
                        AS-konsernet, Bedriftens egen Eika-bankforbindelse samt andre samarbeidspartnere av Eika slik at
                        disse selskapene kan gi Bedriften relevante og tilpassede tips, råd og tilbud om tjenester.
                    </span>
                </section>

                <section class="button-row">
                    <button (click)="goToBankIdSignUp()" [disabled]="!hasAgreedToCustomerAgreement" class="c2a">
                        Opprett bruker med BankID
                    </button>

                    <a class="eika-special-link" href="https://eikaregnskap.no/">Avbryt</a>
                </section>
            }
        </section>
    </article>
</section>
